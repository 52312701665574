import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import Maccordion from "../MAccordion";

const Tab2 = ({ isAppInfoFull, prev, next, data, set, errors }) => {
  /* employer informations */
  const location = useLocation();

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-0 p-0" md="12">
            <Card className=" shadow border-0">
              {/* <Card.Header> */}
              {/* </Card.Header> */}
              {/* <hr className="mx-3" /> */}
              <Card.Body>
                <Form>
                  <Card.Title as="h4" className="">
                    <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                      {" "}
                      {t("tabForms.2.title1")}
                    </div>
                  </Card.Title>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.1.surname")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.prenom?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.1.surname")}
                          name="prenom"
                          value={data?.prenom}
                          onChange={(text) => set.setPrenom(text.target.value)}
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.prenom?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.firstN")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.nom?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.1.firstN")}
                          name="nom"
                          value={data?.nom}

                          onChange={(text) => set.setNom(text.target.value)}
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.nom?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.dob")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.dateNaissance?.message && "border-danger"
                            }`}
                          defaultValue=""
                          value={data.dateNaissance}
                          type="date"
                          name="dateNaissance"

                          onChange={(text) =>
                            set.setBirthday(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.dateNaissance?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.bp")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.lieuNaissance?.message && "border-danger"
                            }`}
                          value={data?.lieuNaissance}
                          placeholder={t("tabForms.1.bp")}
                          type="text"
                          name="lieuNaissance"
                          onChange={(text) =>
                            set.setLieuNaissance(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.lieuNaissance?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.gender")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={`shadow-sm border rounded ${errors?.genre?.message && "border-danger"
                            }`}
                          id="gende"
                          name="genre"
                          options={data.genres}
                          value={data.genre}
                          onChange={(selected) => {
                            set.setGenre(selected);
                          }}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.genre?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.cc")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={`shadow-sm border rounded ${errors?.codePays?.message && "border-danger"
                            }`}
                          id="country"
                          name="codePays"
                          options={data.countries}
                          value={data.codePays}
                          onChange={(selected) => {
                            set.setCountry(selected);
                          }}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.codePays?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.nationality")}{" "}
                          <span className="text-danger">*</span>
                        </label>

                        {data?.nationalities && <Select
                          className={`shadow-sm border rounded ${errors?.nationalite?.message && "border-danger"
                            }`}
                          id="nationalite"
                          name="nationalite"
                          options={data?.nationalities}
                          value={data.nationalite}
                          onChange={(selected) => {
                            set.setNationality(selected);
                          }}
                        />}

                        <span className="text-danger">
                          {" "}
                          {errors.nationalite?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.ms")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={`shadow-sm border rounded ${errors?.maritalStatus?.message && "border-danger"
                            }`}
                          id="maritalStatus"
                          name="maritalStatus"
                          options={data.maritals}
                          value={data.maritalStatus}
                          onChange={(selected) => {
                            set.setMaritalStatus(selected);
                          }}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.maritalStatus?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.noc")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.nombreEnfants?.message && "border-danger"
                            }`}
                          // defaultValue="0"
                          placeholder="Number Of Children"
                          name="nombreEnfants"
                          value={data.nombreEnfants}

                          onChange={(text) =>
                            set.setNombreEnfants(text.target.value)
                          }
                          type="number"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.nombreEnfants?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.natLang")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.nativeLanguage?.message && "border-danger"
                            }`}
                          // defaultValue="English"
                          placeholder="Your Native Language"
                          type="text"
                          name="nativeLanguage"
                          value={data.nativeLanguage}

                          onChange={(text) =>
                            set.setNativeLanguage(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.nativeLanguage?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                    {t("tabForms.2.jobDetails")}
                  </div>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.jobTitle")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.jobTitle?.message && "border-danger"
                            }`}
                          placeholder="Job Title"
                          type="text"
                          name="jobTitle"

                          onChange={(text) =>
                            set.setJobTitle(text.target.value)
                          }
                          value={data?.jobTitle}
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.jobTitle?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    {/* <Col md="6">
                                            <Row>
                                                <Col md="8">

                                                    <Form.Group>
                                                        <label>{t('tabForms.2.numberP')} *  <span className='text-danger'>*</span></label>
                                                        <Form.Control className={`shadow-sm ${errors?.numperiod?.message && 'border-danger'}`}
                                                            type="number"
                                                            name="numperiod"
                                                            value={data?.numperiod}
                                                            // disabled={true}
                                                            onChange={(text) => set.setNumperiod(text.target.value)}
                                                        ></Form.Control>
                                                        <span className='text-danger'> {errors.numperiod?.message} </span>

                                                    </Form.Group>
                                                </Col>
                                                <Col md="4">

                                                    <Form.Group>
                                                        <label> Unit* <span className='text-danger'>*</span></label>
                                                        <Select className={`shadow-sm ${errors?.contractDuration?.message && 'border-danger'}`}
                                                            id="contractDuration"
                                                            name="contractDuration"
                                                            options={data.contractDurations}
                                                            value={data.contractDuration}
                                                            onChange={(selected) => {
                                                                set.setContractDuration(selected);
                                                            }} />
                                                        <span className='text-danger'> {errors.contractDuration?.message} </span>

                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        </Col> */}

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.contractED")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.contractStart?.message && "border-danger"
                            }`}
                          type="date"
                          name="contractStart"
                          value={data?.contractStart}

                          onChange={(text) =>
                            set.setContractStart(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.contractStart?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.contractEnd")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.contractEnd?.message && "border-danger"
                            }`}
                          type="date"
                          name="contractEnd"
                          value={data?.contractEnd}

                          onChange={(text) =>
                            set.setContractEnd(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.contractEnd?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                    {t("tabForms.2.salaryDetails")}
                  </div>

                  <Row>
                    {/* <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.salary")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.salary?.message && "border-danger"
                            }`}
                          type="number"
                          name="salary"
                          placeholder={t("tabForms.2.salary")}
                          value={data?.salary}
                          onChange={(text) => set.setSalary(text.target.value)}
                          min="500"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.salary?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col> */}

                    {/* <Col md="6">
                                            <Form.Group>
                                                <label> {t('tabForms.2.cSalary')} * <span className='text-danger'>*</span></label>
                                                <Form.Control className={`shadow-sm ${errors?.confirm_salary?.message && 'border-danger'}`}
                                                    type="number"
                                                    name="confirm_salary"
                                                    placeholder={t('tabForms.2.cSalary')}
                                                    onChange={(text) => set.setConfirmSalary(text.target.value)}
                                                    min="500"

                                                ></Form.Control>
                                                <span className='text-danger'> {errors.confirm_salary?.message} </span>

                                            </Form.Group>
                                        </Col> */}

                  </Row>



                  {/* Indemnite */}
                  <Row className="row row-cols-5">
                    <Col>
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.bSalary")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.baseSalary?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.bSalary")}
                          type="number"
                          name="baseSalary"
                          value={data?.baseSalary}


                          onChange={(text) =>
                            set.setBaseSalary(text.target.value)
                          }
                          min="500"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.baseSalary?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.indemnite de logement")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.indLogement?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.indemnite de logement")}
                          type="number"
                          name="indLogement"
                          min="0"
                          value={data?.indLogement}
                          onChange={(text) => set.setIndLogement(text.target.value)}
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.indLogement?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.indemnite de transport")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.indTransport?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.indemnite de transport")}
                          type="number"
                          name="indTransport"
                          min="0"
                          value={data?.indTransport}
                          onChange={(text) => set.setIndTransport(text.target.value)}
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.indTransport?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.prime de reponsabilite")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.indResponsabilite?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.prime de reponsabilite")}
                          type="number"
                          name="indTransport"
                          min="0"
                          value={data?.indResponsabilite}
                          onChange={(text) => set.setIndResponsabilite(text.target.value)}
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.indResponsabilite?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.prime de representation")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.indRepresentation?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.prime de reponsabilite")}
                          type="number"
                          name="indRepresentation"
                          min="0"
                          value={data?.indRepresentation}
                          onChange={(text) => set.setIndRepresentation(text.target.value)}
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.indRepresentation?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>


                  </Row>

                  <Maccordion title={t("tabForms.2.Autres idemnités et primes")}

                  >
                    <Row className="row-cols-1 row-cols-sm-5">

                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.prime d'expatriation")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.primeExploitation?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.prime d'expatriation")}
                            type="number"
                            name="primeExploitation"
                            min="0"

                            value={data?.primeExploitation}
                            onChange={(text) => set.setPrimeExploitation(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.primeExploitation?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.sursalaire")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.sursalaire?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.sursalaire")}
                            type="number"
                            name="sursalaire"
                            min="0"
                            value={data?.sursalaire}
                            onChange={(text) => set.setSursalaire(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.sursalaire?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>


                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.prime de salissure")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.primeSalissure?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.prime de salissure")}
                            type="number"
                            name="primeSalissure"
                            min="0"
                            value={data?.primeSalissure}
                            onChange={(text) => set.setPrimeSalissure(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.primeSalissure?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>


                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.indenmite de caisse")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.indCaisse?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.indenmite de caisse")}
                            type="number"
                            name="indCaisse"
                            min="0"
                            value={data?.indCaisse}
                            onChange={(text) => set.setIndCaisse(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.indCaisse?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>




                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.bonus")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.bonus?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.bonus")}
                            type="number"
                            name="bonus"
                            min="0"
                            value={data?.bonus}
                            onChange={(text) => set.setBonus(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.bonus?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.prime de panier")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.primePanier?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.prime de panier")}
                            type="number"
                            name="primePanier"
                            min="0"
                            value={data?.primePanier}
                            onChange={(text) => set.setPrimePanier(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.primePanier?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.billet d'avion")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.billetAvion?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.billet d'avion")}
                            type="number"
                            name="billetAvion"
                            min="0"
                            value={data?.billetAvion}
                            onChange={(text) => set.setbilletAvion(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.billetAvion?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group>
                          <label>
                            {" "}
                            {t("tabForms.2.autres primes")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.autresPrimes?.message && "border-danger"
                              }`}
                            defaultValue=""
                            placeholder={t("tabForms.2.autres primes")}
                            type="number"
                            name="autresPrimes"
                            min="0"
                            value={data?.autresPrimes}
                            onChange={(text) => set.setAutresPrimes(text.target.value)}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.autresPrimes?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>





                    </Row>
                  </Maccordion>

                  {/* <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.fees")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.professionalFees?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.fees")}
                          type="text"
                          name="professionalFees"
                          onChange={(text) =>
                            set.setProfessionalFees(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.professionalFees?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                    {t("tabForms.2.contactDetails")}
                  </div>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.email")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.email?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder="Email"
                          name="email"
                          value={data?.email}

                          onChange={(text) => set.setEmail(text.target.value)}
                          type="email"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.email?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.phoneN")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.phone?.message && "border-danger"
                            }`}
                          defaultValue=""
                          name="phone"
                          value={data.phone}
                          onChange={(text) => set.setPhone(text.target.value)}
                          placeholder="Phone Number"
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.phone?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                    {t("tabForms.2.pAddress")}
                  </div>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.houseNumber")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.numeroMaisonPermanente?.message &&
                            "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.houseNumber")}
                          type="string"
                          name="numeroMaisonPermanente"
                          value={data?.numeroMaisonPermanente}

                          onChange={(text) =>
                            set.setNumeroMaisonPermanente(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.numeroMaisonPermanente?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    {/* <Col md="6">

                                            <Form.Group>
                                                <label> {t('tabForms.2.address1')} * <span className='text-danger'>*</span></label>
                                                <Form.Control className={`shadow-sm ${errors?.adresse1Permanente?.message && 'border-danger'}`}
                                                    defaultValue=''
                                                    placeholder={t('tabForms.2.address1')}
                                                    name="adresse1Permanente"
                                                    onChange={(text) => set.setAdresse1Permanente(text.target.value)}
                                                    type="text"
                                                ></Form.Control>
                                                <span className='text-danger'> {errors.adresse1Permanente?.message} </span>

                                            </Form.Group>
                                        </Col> */}

                    {/* <Col md="6">

                                            <Form.Group>
                                                <label>{t('tabForms.2.address2')} * <span className='text-danger'>*</span></label>
                                                <Form.Control className={`shadow-sm ${errors?.adresse2Permanente?.message && 'border-danger'}`}
                                                    defaultValue=''
                                                    placeholder={t('tabForms.2.address2')}
                                                    name="adresse2Permanente"
                                                    onChange={(text) => set.setAdresse2Permanente(text.target.value)}
                                                    type="text"
                                                ></Form.Control>
                                                <span className='text-danger'> {errors.adresse2Permanente?.message} </span>

                                            </Form.Group>
                                        </Col> */}
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.city")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.villePermanente?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.city")}
                          name="villePermanente"
                          value={data?.villePermanente}

                          onChange={(text) =>
                            set.setVillePermanente(text.target.value)
                          }
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.villePermanente?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.state")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.regionPermanente?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.state")}
                          name="regionPermanente"
                          value={data?.regionPermanente}

                          onChange={(text) =>
                            set.setRegionPermanente(text.target.value)
                          }
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.regionPermanente?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.postal")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.codePostalPermanente?.message &&
                            "border-danger"
                            }`}
                          defaultValue=""
                          placeholder={t("tabForms.2.postal")}
                          name="codePostalPermanente"
                          value={data?.codePostalPermanente}

                          onChange={(text) =>
                            set.setCodePostalPermanente(text.target.value)
                          }
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.codePostalPermanente?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.country")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={`shadow-sm border rounded ${errors?.paysPermanente?.message && "border-danger"
                            }`}
                          id="paysPermanente"
                          name="paysPermanente"
                          options={data.countries}
                          value={data.paysPermanente}
                          onChange={(selected) => {
                            set.setPaysPermanente(selected);
                          }}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.paysPermanente?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="alert alert-success text-uppercase shadow-sm mx-0 h4">
                    {t("tabForms.2.eContact")}
                  </div>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.fullName")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.fullNameE?.message && "border-danger"
                            }`}
                          defaultValue=""
                          name="fullNameE"
                          value={data.fullNameE}
                          onChange={(text) =>
                            set.setFullNameE(text.target.value)
                          }
                          placeholder={t("tabForms.2.fullName")}
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.fullNameE?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.phoneN")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.phoneE?.message && "border-danger"
                            }`}
                          defaultValue=""
                          name="phoneE"
                          value={data.phoneE}
                          onChange={(text) => set.setPhoneE(text.target.value)}
                          placeholder="Phone Number"
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.phoneE?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.email")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.emailE?.message && "border-danger"
                            }`}
                          defaultValue=""
                          placeholder="Email"
                          name="emailE"
                          value={data?.emailE}

                          onChange={(text) => set.setEmailE(text.target.value)}
                          type="email"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.emailE?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col>
                      <Button
                        className="btn-fill pull-right  shadow border-0 shadow border-0"
                        onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); next(1) }}
                        variant="success"
                        style={{
                          backgroundColor: "#008374",
                        }}
                      >
                        {t("tabForms.save")} & {t("tabForms.next")}
                      </Button>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Tab2;
