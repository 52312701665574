import React, { Component, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";

import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { ACCOUNT_STATUS } from "../../GLOBAL_VARIABLE";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const Tab1 = ({
  compte,
  isVisaDetailFull,
  errors,
  next,
  prev,
  set,
  data,
  employer,

}) => {
  console.log("🚀 ~ data:", data)
  const { i18n } = useTranslation();

  /* employer informations */
  const location = useLocation();
  // console.log("employer?.passportNumber", employer?.visaNumber)

  // const [dateExpiration, setDateExpiration] = useState(
  //   // dayjs(
  //   //   employer?.passportExpDate || location.state?.employer?.passportExpDate
  //   // ).format("YYYY-MM-DD")
  // );
  // const [visaNumber, setVisaNumber] = useState(
  //   employer?.visaNumber || location?.state?.employer?.visaNumber
  // );

  // useEffect(() => {
  //   set.setNumeroPasseport(
  //     employer?.passportNumber || location?.state?.employer?.passportNumber
  //   );
  // }, []);

  // useEffect(() => {
  //   set.setVisaNumber(visaNumber);
  //   set.setDataExpiration(dateExpiration);
  // }, [visaNumber, dateExpiration]);


  useEffect(() => { }, [employer])

  return (
    <>
      <Container className="m-0 p-0" fluid>
        <Row className="m-0 p-0">
          <Col md="12" className="m-0 p-0">
            <Card className="p-0 shadow border-0">
              <Card.Body>
                <Form>
                  <div className="alert alert-success text-uppercase mt-5 mb-0 py-2 shadow-sm mx-0 h4">
                    {t("tabForms.1.section-1")}
                  </div>

                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.passN")}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.numeroPasseport?.message && "border-danger"
                            }`}
                          type="text"
                          // disabled
                          // value={
                          //   (location?.state?.employer != null &&
                          //     location?.state?.employer?.passportNumber) ||
                          //   employer?.passportNumber
                          // }
                          value={data?.numeroPasseport}
                          name="numeroPasseport"
                          placeholder={t("tabForms.1.passN")}
                          onChange={(text) =>
                            set.setNumeroPasseport(text.target.value)
                          }
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.numeroPasseport?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.passT")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={`shadow-sm  border rounded  ${errors?.typePassport?.message && "border-danger"
                            }`}
                          id="typePassport"
                          name="typePassport"
                          options={data.typeVisas}
                          value={data.typePassport}
                          onChange={(selected) => {
                            set.setTypePassport(selected);
                          }}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.typePassport?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.doi")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.dateEmission?.message && "border-danger"
                            }`}
                          defaultValue=""
                          name="dateEmission"
                          value={data?.dateEmission}

                          onChange={(text) =>
                            set.setDateEmission(text.target.value)
                          }
                          type="date"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.dateEmission?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.poi")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.lieuEmission?.message && "border-danger"
                            }`}
                          defaultValue=""
                          name="lieuEmission"
                          value={data?.lieuEmission}
                          onChange={(text) =>
                            set.setLieuissue(text.target.value)
                          }
                          placeholder={t("tabForms.1.poi")}
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.lieuEmission?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.expDate")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.dateExpiration?.message && "border-danger"
                            }`}
                          //   defaultValue={}
                          value={data?.dateExpiration}
                          type="date"
                          name="dateExpiration"
                          onChange={(text) => {
                            set.setDataExpiration(text.target.value);

                          }}
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.dateExpiration?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="alert alert-success text-uppercase mt-5 mb-0 py-2 shadow-sm mx-0 h4">
                    {t("tabForms.1.section-2")}
                  </div>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("Avez vous un visa ?")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Select
                          className={`shadow-sm border rounded ${errors?.hasVisa?.message && "border-danger"
                            }`}
                          id="hasVisa"
                          name="hasVisa"
                          options={data.choices}
                          value={data.hasVisa}
                          onChange={(selected) => {
                            set.setHasVisa(selected);
                          }}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.hasVisa?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>

                    {<Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.1.visaNumber")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.visaNumber?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.1.visaNumber")}
                          name="visaNumber"
                          value={data?.visaNumber}
                          onChange={(text) => set.setVisaNumber(text.target.value)}
                          type="text"
                          disabled={!data?.hasVisa?.value}
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.visaNumber?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    }
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.1.visaPurpose")}{" "}
                          {/* <span className="text-danger">({t("Optionel")})</span> */}
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.visaPurpose?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.1.visaPurpose")}
                          name="visaPurpose"
                          onChange={(text) =>
                            set.setVisaPurpose(text.target.value)
                          }
                          type="text"
                          disabled={!data?.hasVisa?.value}
                          value={data?.visaPurpose}

                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.visaPurpose?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.tof")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Select
                          className={`shadow-sm  border rounded ${errors?.typeVisa?.message && "border-danger"
                            }`}
                          id="typeVisa"
                          name="typeVisa"
                          options={data.typeVisas}
                          value={data.typeVisa}
                          onChange={(selected) => {
                            set.setTypeVisa(selected);
                          }}
                          isDisabled={!data?.hasVisa?.value}

                        />
                        <span className="text-danger">
                          {" "}
                          {errors.typeVisa?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.1.poi")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.placeIssue?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.1.poi")}
                          name="placeIssue"
                          onChange={(text) =>
                            set.setPlaceIssue(text.target.value)
                          }
                          type="text"
                          disabled={!data?.hasVisa?.value}
                          value={data?.placeIssue}

                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.placeIssue?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.toe")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Select
                          className={`shadow-sm border rounded  ${errors?.typeEntree?.message && "border-danger"
                            }`}
                          id="typeEntree"
                          name="typeEntree"
                          options={
                            i18n.language === "en"
                              ? data.typeEntrees.en
                              : data.typeEntrees.fr
                          }
                          value={data.typeEntree}
                          onChange={(selected) => {
                            set.setTypeEntry(selected);
                          }}
                          isDisabled={!data?.hasVisa?.value}


                        />

                        <span className="text-danger">
                          {" "}
                          {errors.typeEntree?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.1.vsd")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.dateDepart?.message && "border-danger"
                            }`}
                          type="date"
                          name="dateDepart"
                          onChange={(text) =>
                            set.setDateDepart(text.target.value)
                          }
                          disabled={!data?.hasVisa?.value}
                          value={data?.dateDepart}

                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.dateDepart?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.1.ved")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.dateRetour?.message && "border-danger"
                            }`}
                          type="date"
                          name="dateRetour"
                          onChange={(text) =>
                            set.setDateRetour(text.target.value)
                          }
                          disabled={!data?.hasVisa?.value}
                          value={data?.dateRetour}

                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.dateRetour?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <>
                    <div className="alert alert-success text-uppercase mt-5 mb-0 py-2 shadow-sm mx-0 h4">
                      {t("foreignworkers.addEmployer.residencePermitNumberTitle")}
                    </div>

                    <Row className="row-cols-2">
                      <Col className="pr-1" >
                        <Form.Group>
                          <label>
                            {t("foreignworkers.addEmployer.hasResidentPermit")}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            className={`shadow-sm border rounded ${errors?.hasSejour?.message && "border-danger"
                              }`}
                            id="hasSejour"
                            name="hasSejour"
                            options={data.choices}
                            value={data.hasSejour}
                            onChange={(selected) => {
                              // console.log("🚀 ~ selected:", selected)
                              set.setHasSejour(selected);
                            }}
                          />
                          <span className="text-danger">
                            {" "}
                            {errors.hasSejour?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>


                      {<Col className="pr-1" >
                        <Form.Group>
                          <label>
                            {t("foreignworkers.addEmployer.residencePermitNumber")}

                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.numero_cs?.message && "border-danger"
                              }`}
                            placeholder={t("foreignworkers.addEmployer.residencePermitNumber")}
                            name="numero_cs"
                            value={data?.numero_cs}
                            onChange={(text) => set.setNumero_cs(text.target.value)}
                            type="text"
                            disabled={!data?.hasSejour?.value}
                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.numero_cs?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>
                      }

                      <Col className="pr-1" >
                        <Form.Group>
                          <label>
                            {t("foreignworkers.addEmployer.residencePermitObtDate")}

                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.date_obtention_cs?.message && "border-danger"
                              }`}
                            type="date"
                            name="date_obtention_cs"
                            onChange={(text) =>
                              set.setDate_obtention_cs(text.target.value)
                            }
                            disabled={!data?.hasSejour?.value}
                            value={data?.date_obtention_cs}

                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.date_obtention_cs?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>

                      <Col className="pr-1" >
                        <Form.Group>
                          <label>
                            {t("foreignworkers.addEmployer.residencePermitExpDate")}

                          </label>
                          <Form.Control
                            className={`shadow-sm ${errors?.date_expiration_cs?.message && "border-danger"
                              }`}
                            type="date"
                            name="date_expiration_cs"
                            onChange={(text) =>
                              set.setDate_expiration_cs(text.target.value)
                            }
                            disabled={!data?.hasSejour?.value}
                            value={data?.date_expiration_cs}

                          ></Form.Control>
                          <span className="text-danger">
                            {" "}
                            {errors.date_expiration_cs?.message}{" "}
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>

                  </>

                  <div className="alert alert-success text-uppercase mt-5 mb-0 py-2 shadow-sm mx-0 h4">
                    {t("tabForms.1.section-3")}
                  </div>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.poe")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.portEntry?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.2.poe")}
                          name="portEntry"
                          disabled={!data?.hasSejour?.value}

                          onChange={(text) =>
                            set.setPortENtry(text.target.value)
                          }
                          type="text"
                          value={data?.portEntry}

                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.portEntry?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.arrivalDate")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.arrivalDate?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.2.arrivalDate")}
                          disabled={!data?.hasSejour?.value}
                          name="arrivalDate"
                          onChange={(text) =>
                            set.setArrivalDate(text.target.value)
                          }
                          type="date"
                          value={data?.arrivalDate}

                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.arrivalDate?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.maxLengthStay")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.lengthStay?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.2.maxLengthStay")}
                          name="lengthStay"
                          value={data?.lengthStay}
                          disabled={!data?.hasSejour?.value}

                          onChange={(text) =>
                            set.setLengthStay(text.target.value)
                          }
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.lengthStay?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {t("tabForms.2.addressCmr")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.addressCmr?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.2.addressCmr")}
                          name="addressCmr"
                          value={data?.addressCmr}
                          disabled={!data?.hasSejour?.value}

                          onChange={(text) =>
                            set.setAddressCmr(text.target.value)
                          }
                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.addressCmr?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.destinations")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          className={`shadow-sm ${errors?.placeWork?.message && "border-danger"
                            }`}
                          placeholder={t("tabForms.2.destinations")}
                          disabled={!data?.hasSejour?.value}
                          name="placeWork"
                          onChange={(text) =>
                            set.setPlaceWork(text.target.value)
                          }
                          value={data?.placeWork}

                          type="text"
                        ></Form.Control>
                        <span className="text-danger">
                          {" "}
                          {errors.placeWork?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label>
                          {" "}
                          {t("tabForms.2.modestrans")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={`shadow-sm  border rounded ${errors?.modeTransport?.message && "border-danger"
                            }`}
                          id="typePasseport"
                          isDisabled={!data?.hasSejour?.value}
                          name="modeTransport"
                          options={data.modeTransports}
                          value={data.modeTransport}
                          onChange={(selected) => {
                            // console.log("🚀 ~ selected:", selected)
                            set.setModeTransport(selected);
                          }}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.modeTransport?.message}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      className="btn-fill pull-left  shadow border-0"
                      onClick={() => prev()}
                      variant="success"
                      style={{
                        backgroundColor: "#008374",
                      }}
                    >
                      {t("tabForms.prev")}
                    </Button>
                    {/* <Col>
                                                              <Button
                                                                  className="btn-fill pull-center"
                                                                  type="submit"
                                                                  variant="info"
                                                              >
                                                                  Update
                                                              </Button>
                                                          </Col> */}

                    <Button
                      className="btn-fill pull-right  shadow border-0"
                      onClick={() => {
                        // document.getElementsByTagName('form')[0].submit()
                        next(2);
                      }}
                      variant="success"
                      style={{
                        backgroundColor: "#008374",
                      }}
                    >
                      {t("tabForms.save")} & {t("tabForms.next")}

                    </Button>
                  </div>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Tab1;
