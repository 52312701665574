import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RequiremetsEn() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (i18n.language == "fr") {
            navigate("/requirements");
        }
    }, [i18n])
    return (
        <>
            <Header />
            <div className="container ">

                <h1 className="text-center p-4 m-6">
                    Useful Information
                </h1>
                <hr></hr>
                <div className="section m-md-5  shadow p-2 p-md-4" data-aos="fade-out">

                    <h2>
                        1 – Prerequisites for individuals of foreign nationality intending to work in Cameroon.
                    </h2>
                    <p>
                        Foreign nationals who wish to engage in professional activities in Cameroon must, prior to commencing work, have their employment contract endorsed by the Minister in charge of employment matters, as stipulated in Article 27, paragraph (2) of the Labor Code. Similarly, any renewal of a visa is subject to the same requirement (Article 25, paragraph 2).
                    </p>
                    <p>
                        The procedure for obtaining this endorsement is as follows:
                    </p>
                    <ul>
                        <li>
                            The employment contract is submitted or sent by registered mail, with acknowledgment of receipt, to the competent services of the Ministry of Employment and Vocational Training.
                        </li>
                        <li>
                            The submission or mailing is carried out by the employer in six (06) copies, dated and signed by both parties.
                        </li>
                    </ul>

                    <p>
                        The file must include all documents that establish the worker's qualifications and professional competence for the job and category specified in the contract, or that justify the position being filled by a foreigner, particularly:
                    </p>
                    <ol>
                        <li>
                            A medical certificate issued within the last three (03) months by a medical authority at the recruitment location, certifying that the individual is fit to perform the work in Cameroon.
                        </li>
                        <li>
                            The candidate's professional or academic references, such as certified copies of work certificates or diplomas.
                        </li>

                        <li>
                            A detailed descriptive note of the position, highlighting the required profile.
                        </li>

                        <li>
                            The candidate's curriculum vitae.
                        </li>

                        <li>
                            A criminal record extract issued within the last three (03) months or any equivalent legal document.
                        </li>

                        <li>
                            A certified copy of the entry and residence permit for expatriates already present in Cameroon.
                        </li>

                        <li>
                            A copy of the contract, agreement, or any other document justifying the occupation of the position by a foreigner.
                        </li>

                        <li>
                            The employment Cameroonianization plan, approved by the Minister in charge of employment matters.
                        </li>

                        <li>
                            A detailed organizational chart of the company, showing all managerial and supervisory positions, along with corresponding profiles.
                        </li>

                        <li>
                            A presentation note on the labor force situation, detailing the following aspects:
                            <ul>
                                <li>
                                    The total workforce broken down by nationality and category.
                                </li>
                                <li>
                                    Positions held by foreign workers, along with their profiles and seniority within the company.
                                </li>
                                <li>
                                    Recruitment conducted or planned in the short term, along with corresponding profiles.
                                </li>

                                <li>
                                    The company’s policy for promoting the national workforce.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>

                <div className="section  m-md-5  shadow p-2 p-md-4" data-aos="fade-up">
                    <h2>
                        2- RECRUITMENT PROCEDURES
                    </h2>

                    <p>
                        Provisions outlined in points 9 and 10 of Circular No. 005/PM/ of June 13, 2012, regarding general clauses applicable to foreign investors, stipulate that, notwithstanding the provisions of the Labor Code concerning the endorsement of contracts for foreign workers, positions for laborers, workers, employees, or supervisory agents are to be prioritized for national workers.
                    </p>

                    <p>
                        In all cases, positions must be filled by qualified and competent nationals, where available, in the following proportions:
                    </p>

                    <ul>
                        <li>
                            At least 50% for managerial positions.
                        </li>
                        <li>
                            At least 60% for supervisory positions.
                        </li>
                        <li>
                            At least 85% for operational positions.
                        </li>
                    </ul>

                    <p>
                        Furthermore, the recruitment of personnel by foreign investors must comply with the Labor Code, the collective agreement of the relevant sector, and the specific provisions of this circular.
                    </p>
                </div>


                <div className="section  m-md-5  shadow p-2  p-md-4" data-aos="fade-left">
                    <h2>
                        3– TECHNOLOGY TRANSFER
                    </h2>

                    <p>
                        The foreign investor commits to implementing training programs for national personnel in the relevant sector, as well as in all areas of their activities and commercial development, with the objective of progressively "Cameroonizing" positions of responsibility and facilitating technology transfer.
                    </p>

                    <p>
                        Regarding training, the investor ensures continuous, ongoing training and professional development for their personnel in the trades and specialties of the sector concerned. To this end, the company:
                    </p>

                    <ul>
                        <li>
                            Provides material, financial, or other support for secondary and higher education policies related to the trades of the sector.
                        </li>
                        <li>
                            Encourages research and development within its field of activity.
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RequiremetsEn;