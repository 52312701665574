import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import sidebarImage from "../assets/img/sidebar-3.jpg";
import Tab from "react-bootstrap/Tab";
import axios from "axios";
import { useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Footer from "../components/Footer/Footer";
import Tab1 from "../components/Tabs/Tab1";
import Sidebar from "../components/Sidebar/Sidebar";
import dashboardRoutes from "../routes";
import { Col, Container, Form, Row } from "react-bootstrap";
import Tab2 from "../components/Tabs/Tab2";
import Tab3 from "../components/Tabs/Tab3";
import Tab4 from "../components/Tabs/Tab4";
import Header from "../components/Navbars/AdminNavbar";
import Tab5 from "../components/Tabs/Tab5";
import UseCinetPay from "../hooks/useCinetPay";
import { history } from "../_helpers";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { ACCOUNT_STATUS, DOCUMENTS, DOCUMENT_NUMBER, decrypt } from "../GLOBAL_VARIABLE";
import { useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import PassportNumberModal from "../app/pages/fntec/PassportNumberModal";
import i18next from "i18next";
import { config } from "../constants/config";

const API_URL = `${process.env.REACT_APP_API_URL}/`;

function TabForm() {
  const { i18n } = useTranslation();
  const currentLanguage = i18next?.language;
  const params = useParams();
  console.log("🚀 ~ TabForm ~ params:", params)
  let demandeVisaId = params?.demandeVisaId
  demandeVisaId = decrypt(demandeVisaId)

  const location = useLocation();
  // const nav = useNavigate()
  // useEffect(() => {
  //   demandeVisaId && alert("yes")
  // }, [nav]);
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [successful, setSuccessful] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [compte, setCompte] = React.useState({});
  const [currentTab, setCurrentTab] = React.useState(0);

  // useEffect(() => {
  //   alert("nav")
  // }, [navigation]);
  // comportements
  const [loading, errorp, startPayment] = UseCinetPay();

  // const startPay = (item) => {
  //   startPayment({
  //     amount: item.baseSalary * 2,
  //   });
  // };

  //VISA DETAILS DATAs
  const [countries, setCountries] = React.useState([]);
  const [nationalities, setNationalities] = React.useState([]);
  const [typeVisas, setTypeVisas] = React.useState([
    { label: i18n?.language == 'fr' ? "Ordinaire" : "Ordinary", value: "Ordinary" },
    { label: i18n?.language == 'fr' ? "Diplomatique" : "Diplomatic", value: "Diplomatic" },
  ]);

  const [typeEntrees, setTypeEntrees] = React.useState({
    fr: [
      { label: "Simple", value: "Simple" },
      { label: "Multiple", value: "Multiple" },
    ],

    en: [
      { label: "Simple", value: "Simple" },
      { label: "Multiple", value: "Multiple" },
    ],
  });

  const [genres, setgenres] = React.useState([
    { label: i18n?.language == 'fr' ? "Masculin" : "Male", value: "Masculin" },
    { label: i18n?.language == 'fr' ? "Féminin" : "Female", value: "Feminin" },
  ]);

  //APPLICATION DETAILS DATAS
  const [maritals, setMaritals] = React.useState([
    { label: i18n?.language == 'fr' ? "Célibataire" : "Single", value: "Célibataire" },
    { label: i18n?.language == 'fr' ? "Marié" : "Married", value: "Marie" },
    { label: i18n?.language == 'fr' ? "Divorcé" : "Divorce", value: "Divorce" },
    { label: i18n?.language == 'fr' ? "Veuf(ve)" : "Widower", value: "Veuf" },
  ]);
  const [currencies, setCurrencies] = React.useState([
    { label: "XAF", value: "XAF" },
    // { label: "Euro", value: "EUR" },
    // { label: "Dollar", value: "$" },
  ]);
  const [contractDurations, setContractDurations] = React.useState([
    { label: i18n?.language == 'fr' ? "Année" : "Year", value: "Year" },
    { label: i18n?.language == 'fr' ? "Mois" : "Month", value: "Month" },
  ]);

  /////////////////////////////////////////////////////////////////////
  /////////////////                   ///////////////////////////////
  ////////////////  Tab1 states       ////////////////////////////////
  ///////////////                    ////////////////////////////////
  /////////////////////////////////////////////////////////////////

  //Passport details
  const [numeroPasseport, setNumeroPasseport] = React.useState("");
  const [typePassport, setTypePassport] = React.useState("");
  const [dateEmission, setDateEmission] = React.useState("");
  const [lieuEmission, setLieuissue] = React.useState("");
  const [dateExpiration, setDataExpiration] = React.useState("");
  const [choices, setChoices] = React.useState([
    { label: i18n?.language == 'fr' ? "Oui" : "Yes", value: true },
    { label: i18n?.language == 'fr' ? "Non" : "No", value: false },
  ]);

  //VisaDetails
  const [hasVisa, setHasVisa] = React.useState(choices[0]);
  const [visaNumber, setVisaNumber] = React.useState("");
  const [visaPurpose, setVisaPurpose] = React.useState("");
  const [typeVisa, setTypeVisa] = React.useState("");
  const [placeIssue, setPlaceIssue] = React.useState("");
  const [typeEntree, setTypeEntry] = React.useState("");
  const [dateDepart, setDateDepart] = React.useState("");
  const [dateRetour, setDateRetour] = React.useState("");


  const [hasSejour, setHasSejour] = React.useState(choices[0]);
  const [numero_cs, setNumero_cs] = React.useState("");
  const [date_obtention_cs, setDate_obtention_cs] = React.useState("");
  const [date_expiration_cs, setDate_expiration_cs] = React.useState("");


  //Travel details
  const [portEntry, setPortENtry] = React.useState("");
  const [arrivalDate, setArrivalDate] = React.useState("");
  const [lengthStay, setLengthStay] = React.useState("");
  const [addressCmr, setAddressCmr] = React.useState("");
  const [placeWork, setPlaceWork] = React.useState("");
  const [modeTransport, setModeTransport] = React.useState("");


  const [modeTransports, setModeTransports] = React.useState([
    { label: i18n?.language == 'fr' ? "Avion" : "Plane", value: "Plane" },
    { label: i18n?.language == 'fr' ? "Train" : "Train", value: "Train" },
    { label: i18n?.language == 'fr' ? "Mer" : "Sea", value: "Sea" },
    { label: i18n?.language == 'fr' ? "Route" : "Road", value: "Road" },
    { label: i18n?.language == 'fr' ? "Autres" : "Others", value: "Others" },
  ]);
  const OPTIONALS = {
    visa: ["visaNumber",
      "visaPurpose",
      "typeVisa",
      "placeIssue",
      "typeEntree",
      "dateDepart",
      "dateRetour",

    ],
    sejour: [
      "numero_cs",
      "date_obtention_cs",
      "date_expiration_cs",
      "portEntry",
      "arrivalDate",
      "lengthStay",
      "addressCmr",
      "placeWork",
      "modeTransport",
    ]
  }
  const [OPTIONAL_FILEDS, SETOPTIONAL_FILEDS] = React.useState({ sejour: [], visa: OPTIONALS.visa })
  console.log("🚀 ~ TabForm ~ OPTIONAL_FILEDS:", OPTIONAL_FILEDS)
  useEffect(() => {
    // console.log("🚀 ~ useEffect ~ hasVisa:", hasVisa)
    if (!hasVisa?.value) {

      setVisaNumber("")
      setVisaPurpose("")
      setTypeVisa("")
      setPlaceIssue("")
      setTypeEntry("")
      setDateDepart('')
      setDateRetour('')

      SETOPTIONAL_FILEDS({ ...OPTIONAL_FILEDS, visa: OPTIONALS.visa })
    } else {
      SETOPTIONAL_FILEDS({ ...OPTIONAL_FILEDS, visa: [] })
    }


  }, [hasVisa, hasSejour]);



  useEffect(() => {
    // console.log("🚀 ~ useEffect ~ hasVisa:", hasVisa)
    if (!hasSejour?.value) {
      // alert("no")
      setNumero_cs("")
      setDate_obtention_cs("")
      setDate_expiration_cs("")


      setPortENtry("")
      setArrivalDate("")
      setLengthStay("")
      setAddressCmr("")
      setPlaceWork("")
      setModeTransport("")

      SETOPTIONAL_FILEDS({ ...OPTIONAL_FILEDS, sejour: OPTIONALS.sejour })
    } else {
      SETOPTIONAL_FILEDS({ ...OPTIONAL_FILEDS, sejour: [] })

    }
  }, [hasSejour]);


  const dataTab1 = {
    numeroPasseport,
    typePassport,
    dateEmission,
    lieuEmission,
    dateExpiration,
    hasVisa,
    visaNumber,
    visaPurpose,
    typeVisa,
    placeIssue,
    typeEntree,
    dateDepart,
    dateRetour,

    hasSejour,
    numero_cs,
    date_obtention_cs,
    date_expiration_cs,


    portEntry,
    arrivalDate,
    lengthStay,
    addressCmr,
    placeWork,
    modeTransport,


    // typeVisas,
    // modeTransports,
    // typeEntrees,

  };

  const setTab1 = {
    setNumeroPasseport,
    setTypePassport,
    setDateEmission,
    setLieuissue,
    setDataExpiration,

    //
    setHasVisa,
    setVisaNumber,
    setVisaPurpose,
    setTypeVisa,
    setPlaceIssue,
    setTypeEntry,
    setDateDepart,
    setDateRetour,

    setHasSejour,
    setNumero_cs,
    setDate_obtention_cs,
    setDate_expiration_cs,
    //

    setPortENtry,
    setArrivalDate,
    setLengthStay,
    setAddressCmr,
    setPlaceWork,
    setModeTransport,
    setModeTransports,
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////                   ///////////////////////////////
  ////////////////  Tab2 Applicants details       ////////////////////////////////
  ///////////////                    ////////////////////////////////
  /////////////////////////////////////////////////////////////////

  ///PERSONAL DETAILS
  const [prenom, setPrenom] = React.useState("");
  const [nom, setNom] = React.useState("");
  const [dateNaissance, setBirthday] = React.useState("");
  const [lieuNaissance, setLieuNaissance] = React.useState("");
  const [genre, setGenre] = React.useState("");
  const [codePays, setCountry] = React.useState("");
  const [nationalite, setNationality] = React.useState("");
  const [maritalStatus, setMaritalStatus] = React.useState("");
  const [nombreEnfants, setNombreEnfants] = React.useState("");
  const [nativeLanguage, setNativeLanguage] = React.useState("");

  ///JobDetails

  const [jobTitle, setJobTitle] = React.useState("");
  // const [contractDuration, setContractDuration] = React.useState("");
  // const [numperiod, setNumperiod] = React.useState(2);
  const [contractStart, setContractStart] = React.useState("");
  const [contractEnd, setContractEnd] = React.useState("");

  //SALARY DETAILS
  // const [salary, setSalary] = React.useState("");
  const [baseSalary, setBaseSalary] = React.useState("");

  const [indLogement, setIndLogement] = useState(0);
  const [indTransport, setIndTransport] = useState(0);
  const [indResponsabilite, setIndResponsabilite] = useState(0);
  const [indRepresentation, setIndRepresentation] = useState(0);
  const [primeExploitation, setPrimeExploitation] = useState(0);
  const [sursalaire, setSursalaire] = useState(0);
  const [primeSalissure, setPrimeSalissure] = useState(0);
  const [indCaisse, setIndCaisse] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [primePanier, setPrimePanier] = useState(0);
  const [billetAvion, setbilletAvion] = useState(0);
  const [autresPrimes, setAutresPrimes] = useState(0);


  // const [confirm_salary, setConfirmSalary] = React.useState("");
  const [currency, setCurrency] = React.useState("XAF");
  // const [prime, setPrime] = React.useState("");
  // const [professionalFees, setProfessionalFees] = React.useState("");
  // const [indemnite, setIndemnite] = React.useState("");

  //CONTACT DETAILS
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  //PERMANAT ADDRESS
  const [numeroMaisonPermanente, setNumeroMaisonPermanente] =
    React.useState("");
  // const [adresse1Permanente, setAdresse1Permanente] = React.useState("");
  // const [adresse2Permanente, setAdresse2Permanente] = React.useState("");
  const [villePermanente, setVillePermanente] = React.useState("");
  const [regionPermanente, setRegionPermanente] = React.useState("");
  const [codePostalPermanente, setCodePostalPermanente] = React.useState("");
  const [paysPermanente, setPaysPermanente] = React.useState("");

  // EMERGENCY CONTACT
  const [fullNameE, setFullNameE] = React.useState("");
  const [phoneE, setPhoneE] = React.useState("");
  const [emailE, setEmailE] = React.useState("");
  const navigate = useNavigate()


  const setTab2 = {
    setPrenom,
    setNom,
    setBirthday,
    setLieuNaissance,
    setGenre,
    setCountry,
    setNationality,
    setMaritalStatus,
    setNombreEnfants,
    setNativeLanguage,

    //

    setJobTitle,
    // setContractDuration,
    // setNumperiod,

    //

    setContractStart,
    setContractEnd,
    // setSalary,
    // setConfirmSalary,



    setIndLogement,
    setIndTransport,
    setIndResponsabilite,
    setIndRepresentation,
    setPrimeExploitation,
    setSursalaire,
    setPrimeSalissure,
    setIndCaisse,
    setBonus,
    setPrimePanier,
    setbilletAvion,
    setAutresPrimes,

    setCurrency,
    // setPrime,
    // setProfessionalFees,
    // setIndemnite,
    setBaseSalary,
    //

    setEmail,
    setPhone,

    //


    setNumeroMaisonPermanente,
    // setAdresse1Permanente,
    // setAdresse2Permanente,
    setVillePermanente,
    setRegionPermanente,
    setCodePostalPermanente,
    setPaysPermanente,

    //
    setFullNameE,
    setPhoneE,
    setEmailE,
  };

  const dataTab2 = {
    prenom,
    nom,
    dateNaissance,
    lieuNaissance,

    genre,
    codePays,
    nationalite,
    maritalStatus,
    nombreEnfants,
    nativeLanguage,

    //
    // contractDuration,
    //
    jobTitle,
    // numperiod,
    contractStart,
    contractEnd,
    // confirm_salary,

    // salary,
    baseSalary,
    indLogement,
    indTransport,
    indResponsabilite,
    indRepresentation,
    primeExploitation,
    sursalaire,
    primeSalissure,
    indCaisse,
    bonus,
    primePanier,
    billetAvion,
    autresPrimes,
    // dateExpiration,




    currency,
    // prime,
    // professionalFees,
    // indemnite,

    //
    email,
    phone,

    //
    // adresse1Permanente,
    // adresse2Permanente,
    numeroMaisonPermanente,
    villePermanente,
    regionPermanente,
    codePostalPermanente,
    paysPermanente,

    //
    fullNameE,
    phoneE,
    emailE,

    // genres,
    // maritals,
    // countries,
    // nationalities,
    // // contractDurations,
    // currencies,
  };

  //DOCUMENTS
  const [documents, setDocuments] = React.useState({});
  const [jobs, setJobs] = React.useState([]);

  const fetchCountry = () => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        // setCountries(response.data);
        let countrie = [];
        setNationalities(response?.data?.map((item) => {
          return {
            label: i18n?.language == 'fr' ? item?.demonyms?.fra?.f : item?.demonyms?.eng?.f,
            value: item?.demonyms?.fra?.f
          }

        }))
        response.data.forEach((data) => {
          countrie.push({ label: i18n?.language == 'fr' ? data.translations?.fra?.common : data.name?.common, value: data.name?.common });

        });
        setCountries(
          countrie.sort((a, b) => {
            const nameA = a.label.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchJobs = () => {
    axios
      .get(`${config.app.fntec_url}/occupations`)
      .then(async (response) => {
        if (response && response?.status == 200) {
          console.log("Jobs ", response);
          setJobs(response?.data?.data.map((item) => {
            return {
              label: item?.name,
              value: item?.id
            }
          }));
        }
      })
      .catch((e) => {
        console.log("Jobs error", e);
      });
  };

  React.useEffect(() => {
    fetchCountry();
  }, [i18n]);

  React.useEffect(() => {
    fetchCountry();
    fetchJobs();
  }, []);

  const fetchCompte = () => {
    setSuccessful(true);
    axios
      .get(API_URL + "comptes" + "/" + currentUser?.user?.compte_id, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        if (response.data.data.status != ACCOUNT_STATUS.VALIDATED) {
          navigate("/user");
        }
        setSuccessful(false);
        setCompte(response.data.data);
      })
      .catch((e) => {

        navigate("/user");
        setSuccessful(false);
        setCompte(null);
      });
  };

  const [demandeVisa, setDemandeVisa] = useState(null);




  // console.log("🚀 ~ TabForm ~ demandeVisaId:", demandeVisaId)

  const fetchDemande = () => {
    // console.log("demandeVisaId  ", demandeVisaId )
    // console.log("demandeVisa?.id ", demandeVisa?.id )
    let selectedId = (demandeVisaId !== undefined && demandeVisaId !== null) ? demandeVisaId : demandeVisa?.id
    setSuccessful(true);
    axios
      .get(API_URL + "demandeVisas" + "/" + selectedId, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        setSuccessful(false);
        setDemandeVisa(response.data.data);
      })
      .catch((e) => {
        setSuccessful(false);
        // setDemandeVisa(null);
      });
  };

  useEffect(() => {
    (demandeVisaId) && fetchDemande()
  }, [demandeVisaId]);

  const [errors, setErros] = useState({});
  let res = {};
  const [showErrors, setShowErrors] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function checkFields(dataToCheck, checDocs) {
    console.log("🚀 ~ checkFields ~ dataToCheck:", dataToCheck)
    res = {};
    // if (showErrors) {

    // let data = { ...dataTab1, ...dataTab2 }
    // eslint-disable-next-line array-callback-return
    Object.keys(dataToCheck).map((item) => {
      if (item.includes("email")) {
        if (
          !dataToCheck[item]?.match(
            /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
          )
        ) {
          res[item] = { message: "Must be a valid email address" };
        }
      }
      const regex =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      if (item.includes("phone") && dataToCheck[item]) {

        if (!dataToCheck[item].match(regex)) {
          res[item] = {
            message: "Must be a valid phone number (ex : +237...)",
          };
        }
      }
      if (dataToCheck[item] === "" || dataToCheck[item] === null || dataToCheck[item] === undefined)
        if (!OPTIONAL_FILEDS.visa?.includes(item) && !OPTIONAL_FILEDS.sejour?.includes(item)) {
          return (res[item] = { message: t("required") });
        }
      // setErros({ item: { msg: "This field is required" } })
    });
    if (checDocs) {
      console.log("🚀 ~ DOCUMENTS?.map ~ documents:", documents)
      console.log("🚀 ~ DOCUMENTS?.map ~ DOCUMENTS:", DOCUMENTS)
      let missingRequiredDocs = false;

      DOCUMENTS?.forEach((doc) => {
        const dd = Object.keys(documents)?.find((key) => key == doc?.key);
        console.log("🚀 ~ DOCUMENTS?.forEach ~ dd:", dd);
        console.log("🚀 ~ DOCUMENTS?.forEach ~ doc?.required:", doc?.required, doc?.key);

        if (doc?.required === true && (dd == null || dd == undefined)) {
          console.log("🚀   ~ doc:", doc);
          missingRequiredDocs = true;
        }
      });

      if (missingRequiredDocs) {
        res["documents"] = { message: t("requiredDoc") };
      } else {
        setCurrentTab(3);
      }
    }


    setErros({ ...res });

    console.log("Error : ", res);
    // }
    return res;
  }

  // useEffect(() => {
  //   checkField()
  //   console.log('docs : ', Object.keys({ ...dataTab1, ...dataTab2 }))
  // },[
  //   // Object.keys({ ...dataTab1, ...dataTab2 })
  //   dataTab1, dataTab2, checkField
  // ])

  const saveOne = async (dataTab1) => {
    console.log("Save starting stept one...");
    // setShowErrors(true);
    const res = checkFields(dataTab1, false);
    // const res = [];
    console.log("🚀 ~ saveOne ~ res:", res)
    // console.log('fulll : ', isAppInfoFull, isVisaDetailFull,isDocFull)
    console.log("to save  step One: ", dataTab1);
    if (Object.values(res).length !== 0) {
      toast.error(t("globalError"));
    } else if (Object.values(res).length === 0) {
      setSuccessful(true);
      // data.iduser = currentUser?.user?.id;

      // documents.forEach((doc) => {
      //   data[doc.id] = doc.file[0];
      // });
      // console.log("Data to save ", data);
      return axios
        .post(API_URL + `demandeVisas/stepTwo`, {
          ...dataTab1,
          iduser: currentUser?.user?.id,
          demandeId: demandeVisaId || demandeVisa?.id,
          hasVisa: hasVisa?.value ? 1 : 0,
          hasSejour: hasSejour?.value ? 1 : 0,
          typePasseport: typePassport?.value,
          modeTransport: modeTransport?.value,
          typeVisa: typeVisa?.value,
          typeEntree: typeEntree?.value,
          employeRef: employerF?.id
        }, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${currentUser.token}`,
          },
        })
        .then((response) => {
          console.log("response", response);
          setSuccessful(false);
          if (response.status === 201 || response.status === 200) {
            setMessage(currentLanguage == 'en' ? (response.data.message?.en || response.data.message) : (response.data.message?.fr || response.data.message));
            setSuccess(true);
            toast.success(currentLanguage == 'en' ? (response.data.message?.en || response.data.message) : (response.data.message?.fr || response.data.message));
            setDemandeVisa(response.data.data);

            // next(1)
            setCurrentTab(2)
            // startPay(data);
          } else {
            setMessage(response.status === 500 ? "Sorry and error occured. Please try later!" : response.data.message);
            toast.error(response.status === 500 ? "Sorry and error occured. Please try later!" : response.data.message);
            setError(true);
          }
          return response;
        })
        .catch((error) => {
          console.log("error", error);
          setSuccessful(false);
          toast.error(error?.code === 'ERR_NETWORK' ? t('Problème de conexion') : "Sorry and error occured. Please try against");

          return null;
        });
    } else {
      toast.error("Please Complete all required files");
    }
  };


  const saveTwo = async (dataTab2) => {
    // console.log("🚀 ~ saveTwo ~ codePays:", codePays)

    console.log("Save starting stept two...");
    // setShowErrors(true);
    const res = checkFields(dataTab2, false);
    // const res = [];
    // console.log("🚀 ~ saveTwo ~ res:", res)
    // console.log('fulll : ', isAppInfoFull, isVisaDetailFull,isDocFull)
    // console.log("to save  step One: ", dataTab2);
    if (Object.values(res).length !== 0) {
      toast.error(t("globalError"));
    } else if (Object.values(res).length === 0) {
      setSuccessful(true);
      // data.iduser = currentUser?.user?.id;

      // documents.forEach((doc) => {
      //   data[doc.id] = doc.file[0];
      // });
      // console.log("Data to save ", data);
      return axios
        .post(API_URL + `demandeVisas/${demandeVisa?.id}/stepOne`, {
          ...dataTab2,
          iduser: currentUser?.user?.id,
          demandeId: demandeVisaId || demandeVisa?.id,
          nationalite: nationalite?.value,
          maritalStatus: maritalStatus?.value,
          modeTransport: modeTransport?.value,
          paysPermanente: paysPermanente?.value,

          genre: genre?.value,
          codePays: codePays?.value,

          dateExpiration



        }, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${currentUser.token}`,
          },
        })
        .then((response) => {
          console.log("response", response);
          setSuccessful(false);
          if (response.status === 201 || response.status === 200) {
            setMessage(currentLanguage == 'en' ? (response.data.message?.en || response.data.message) : (response.data.message?.fr || response.data.message));
            setSuccess(true);
            toast.success(currentLanguage == 'en' ? (response.data.message?.en || response.data.message) : (response.data.message?.fr || response.data.message));
            setDemandeVisa(response.data.data);

            // next(1)
            setCurrentTab(1)
            // startPay(data);
          } else {
            setMessage(response.status === 500 ? "Sorry and error occured. Please try later!" : response.data.message);
            toast.error(response.status === 500 ? "Sorry and error occured. Please try later!" : response.data.message);
            setError(true);
          }
          return response;
        })
        .catch((e) => {
          console.log("error", e);
          setSuccessful(false);
          toast.error(e?.code === 'ERR_NETWORK' ? t('Problème de conexion') : "Sorry and error occured. Please try against");

          return null;
        });
    } else {
      toast.error("Please Complete all required files");
    }
  };

  const saveThree = async () => {
    console.log("Save starting...");
    const res = checkFields({}, true)
    // console.log("Data to save ", data);
    fetchDemande()
  };

  const save = async () => {
    console.log("Save starting...");
    setSuccessful(true);

    // console.log("Data to save ", data);
    return axios
      .post(API_URL + `demandeVisas/${demandeVisa?.id}/stepFour`, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        setSuccessful(false);
        if (response.status === 201 || response.status === 200) {
          // setMessage(response.data.message);
          setMessage(currentLanguage == 'en' ? (response.data.message?.en || response.data.message) : (response.data.message?.fr || response.data.message));

          setSuccess(true);
          toast.success(currentLanguage == 'en' ? (response.data.message?.en || response.data.message) : (response.data.message?.fr || response.data.message));
          history.navigate("/dashboard");
          // startPayment(response);
        } else {
          setMessage(response.status === 500 ? "Sorry and error occured. Please try later!" : response.data.message);

          // setMessage(response.data.message);
          toast.error(response.status === 500 ? "Sorry and error occured. Please try later!" : response.data.message);
          setError(true);
        }
        return response;
      })
      .catch((e) => {
        console.log("error", e);
        setSuccessful(false);
        toast.error(e?.code === 'ERR_NETWORK' ? t('Problème de conexion') : "Sorry and error occured. Please try against");

        return null;
      });

  };

  const next = (nextIndex) => {
    if (nextIndex === 1) {
      saveTwo(dataTab2)
    } else if (nextIndex === 2) {
      saveOne(dataTab1)
    } else if (nextIndex === 3) {
      saveThree()
    }

    {

    }

    // setCurrentTab(nextIndex);
  };

  const prev = () => {
    setCurrentTab(currentTab - 1);
  };

  React.useEffect(() => {
    fetchCompte();
  }, [currentUser]);

  const dataDocument = { documents };

  const [isVisaDetailFull, setIsVisaDetailFull] = React.useState(true);
  const [isAppInfoFull, setIsAppInfoFull] = React.useState(true);
  const [isDocFull, setIsDocFull] = React.useState(true);

  function isDataFully(data) {
    // const result = Object.keys(data);
    // console.log("State Current data : ", data);
    var full = false;
    Object.keys(data).map((key, index) => {
      // console.log("🚀 ~ result.map ~ key:", key)
      if (data[key] === "") {
        // console.log(item);
        // console.log("🚀 ~ result.map ~ !OPTIONAL_FILEDS.includes(key):", !OPTIONAL_FILEDS.includes(key))
        if (!OPTIONAL_FILEDS.sejour?.includes(key) && !OPTIONAL_FILEDS.visa?.includes(key)) {
          full = true;
        }
      }
    });
    return full;
  }

  useEffect(() => {
    // console.log("language : ", i18n.language);

    isDataFully(dataTab1)
      ? setIsVisaDetailFull(true)
      : setIsVisaDetailFull(false);
    isDataFully(dataTab2) ? setIsAppInfoFull(true) : setIsAppInfoFull(false);
    const result = Object.values(documents);
    // console.log('length', result.length)
    // console.log('doc', result)
    // Object.values(documents).length >= DOCUMENT_NUMBER
    //   ? setIsDocFull(true)
    //   : setIsDocFull(false);
    if (showErrors) checkFields({ ...dataTab1, ...dataTab2 }, true);
  }, [
    hasVisa,
    documents,
    showErrors,
    prenom,
    nom,
    dateNaissance,
    lieuNaissance,
    genre,
    codePays,
    nationalite,
    maritalStatus,
    nombreEnfants,
    nativeLanguage,

    //
    jobTitle,
    // contractDuration,
    // numperiod,
    //
    contractStart,

    ,
    // confirm_salary,
    // currency,
    // prime,
    // professionalFees,
    // indemnite,
    baseSalary,

    //
    email,
    phone,

    //
    numeroMaisonPermanente,
    // adresse1Permanente,
    // adresse2Permanente,
    villePermanente,
    regionPermanente,
    codePostalPermanente,
    paysPermanente,

    //
    fullNameE,
    phoneE,
    emailE,

    genres,
    maritals,
    countries,
    contractDurations,
    currencies,
    numeroPasseport,
    typePassport,
    dateEmission,
    lieuEmission,
    dateExpiration,

    visaNumber,
    visaPurpose,
    typeVisa,
    typeEntree,
    dateDepart,
    dateRetour,

    portEntry,
    arrivalDate,
    lengthStay,
    addressCmr,
    placeWork,
    modeTransport,

    typeVisas,
    modeTransports,
    typeEntrees,
  ]);

  const [employer, setEmployer] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    if (employer != null || employer != undefined) {
      setOpen(false);
    }
  };


  const [employerF, setEmployerF] = useState('');
  console.log("🚀 ~ employerF:", employerF)
  useEffect(() => {
    setEmployerF(location.state?.employer)
  }, [location]);

  useEffect(() => {
    if (employerF) {
      // alert(employerF?.salary)
      setPrenom(employerF?.firstName)
      setNom(employerF?.lastName)
      setBirthday(employerF?.birthDate)
      setNumeroPasseport(employerF?.passportNumber)
      // setVisaNumber(employerF?.visaNumber || "")
      setGenre(employerF?.sexe)
      setJobTitle(employerF?.qualification)
      // setSalary(employerF?.salary)
      // setDataExpiration(employerF?.dateExpiration)
      // setBaseSalary(employerF?.baseSalary || 0)
      // setIndLogement(employerF?.indLogement || 0)
      // setIndTransport(employerF?.indTransport || 0)
      // setIndResponsabilite(employerF?.indResponsabilite || 0)
      // setIndRepresentation(employerF?.indRepresentation || 0)
      // setPrimeExploitation(employerF?.primeExploitation || 0)
      // setSursalaire(employerF?.sursalaire || 0)
      // setPrimeSalissure(employerF?.primeSalissure || 0)
      // setIndCaisse(employerF?.indCaisse || 0)
      // setBonus(employerF?.bonus || 0)
      // setPrimePanier(employerF?.primePanier || 0)
      // setbilletAvion(employerF?.billetAvion || 0)
      // setAutresPrimes(employerF?.autresPrimes || 0)


    } else if (demandeVisa) {
      setCurrentTab((demandeVisa?.step1 != "1" ? "0" : null) || (demandeVisa?.step2 != "1" ? "1" : null) || (demandeVisa?.step3 != "1" ? "2" : null) || (demandeVisa?.step4 != 1 ? "3" : null) || "3")
      // alert("🚀 ~ useEffect ~ demandeVisa?.sept1:", demandeVisa?.step1)  
      //  setCurrentTab("2")
      // console.log("🚀 ~ useEffect ~ demandeVisa?.placeIssue:", lieuEmission)
      // alert("🚀 ~ useEffect ~ demandeVisa:", demandeVisa?.numeroPasseport)
      //////////////////////////
      ////////////////////TAB1/////////////////
      /////////////////////////////////////////////////////////
      setNumeroPasseport(demandeVisa?.numeroPasseport || "")

      setTypePassport(typeVisas?.find((t) => { return t?.value == demandeVisa?.typePasseport }))
      setDateEmission(demandeVisa?.dateEmission || "")
      setLieuissue(demandeVisa?.lieuEmission || "")
      setDataExpiration(demandeVisa?.dateExpiration || "")

      // setHasVisa( choices?.find((c)=> { return c?.value == demandeVisa?.hasVisa}))
      setHasVisa(choices?.find((c) => { return c?.value == demandeVisa?.hasVisa }))
      // setHasVisa(choices?.find((c)=> { return c?.value == demandeVisa?.hasVisa }) || choices[1])
      // setHasVisa(choices[1])
      setVisaNumber(demandeVisa?.visaNumber || "")
      setVisaPurpose(demandeVisa?.visaPurpose || "")
      setTypeVisa(typeVisas?.find((t) => { return t?.value == demandeVisa?.typeVisa }))
      setPlaceIssue(demandeVisa?.placeIssue || "")
      setTypeEntry(typeEntrees?.fr?.find((t) => { return t?.value == demandeVisa?.typeEntree }))
      setDateDepart(demandeVisa?.dateDepart || "")
      setDateRetour(demandeVisa?.dateRetour || "")


      setHasSejour(choices?.find((c) => { return c?.value == demandeVisa?.hasSejour }))
      setNumero_cs(demandeVisa?.numero_cs || "")
      setDate_obtention_cs(demandeVisa?.date_obtention_cs || "")
      setDate_expiration_cs(demandeVisa?.date_expiration_cs || "")

      setPortENtry(demandeVisa?.portEntry || "")
      setArrivalDate(demandeVisa?.arrivalDate || "")
      setLengthStay(demandeVisa?.lengthStay || "")
      setAddressCmr(demandeVisa?.addressCmr || "")
      setPlaceWork(demandeVisa?.placeWork || "")
      setModeTransport(modeTransports?.find((m) => { return m?.value == demandeVisa?.modeTransport }))



      //////////////////////////
      ////////////////////TAB2/////////////////
      /////////////////////////////////////////////////////////

      // setPrenom(demandeVisa?.prenom || "")
      setNom(demandeVisa?.nom || "")
      setBirthday(demandeVisa?.dateNaissance || "")
      setLieuNaissance(demandeVisa?.lieuNaissance || "")
      // setGenre(genres?.find((g) => { return g?.value == demandeVisa?.genre }))
      setCountry(countries?.find((c) => {
        return c?.value == demandeVisa?.codePays
      }))


      setNationality(demandeVisa?.nationalite ? nationalities?.find((c) => {
        return c?.value == demandeVisa?.nationalite
      }) : null)
      setMaritalStatus(maritals?.find((m) => { return m?.value == demandeVisa?.maritalStatus }))
      setNombreEnfants(demandeVisa?.nombreEnfants || 0)
      setNativeLanguage(demandeVisa?.nativeLanguage || "")


      // setJobTitle(demandeVisa?.jobTitle || "")
      // setNumperiod(demandeVisa?.numperiod)
      setContractStart(demandeVisa?.contractStart || "")
      setContractEnd(demandeVisa?.contractEnd || "")
      // setBaseSalary(demandeVisa?.baseSalary || 0)

      setEmail(demandeVisa?.email || "")
      setPhone(demandeVisa?.phone || "")


      // setIndLogement(demandeVisa?.indLogement || 0)
      // setIndTransport(demandeVisa?.indTransport || 0)
      // setIndResponsabilite(demandeVisa?.indResponsabilite || 0)
      // setIndRepresentation(demandeVisa?.indRepresentation || 0)
      // setPrimeExploitation(demandeVisa?.primeExploitation || 0)
      // setSursalaire(demandeVisa?.sursalaire || 0)
      // setPrimeSalissure(demandeVisa?.primeSalissure || 0)
      // setIndCaisse(demandeVisa?.indCaisse || 0)
      // setBonus(demandeVisa?.bonus || 0)
      // setPrimePanier(demandeVisa?.primePanier || 0)
      // setbilletAvion(demandeVisa?.billetAvion || 0)
      // setAutresPrimes(demandeVisa?.autresPrimes || 0)

      setNumeroMaisonPermanente(demandeVisa?.numeroMaisonPermanente || "")
      // setAdresse1Permanente(demandeVisa?.visa)
      // setAdresse2Permanente(demandeVisa?.visa)
      // alert(demandeVisa?.regionPermanente)

      setVillePermanente(demandeVisa?.villePermanente || "")
      setRegionPermanente(demandeVisa?.regionPermanente || "")
      setCodePostalPermanente(demandeVisa?.codePostalPermanente || "")
      setPaysPermanente(countries?.find((c) => { return c?.value == demandeVisa?.paysPermanente }))
      setFullNameE(demandeVisa?.fullNameE || "")
      setPhoneE(demandeVisa?.phoneE || "")
      setEmailE(demandeVisa?.emailE || "")
      setDocuments(
        {
          contract: demandeVisa?.contract,
          passport: demandeVisa?.passport,
          visa: demandeVisa?.visa,
          cv: demandeVisa?.cv,
          medical: demandeVisa?.medical,
          criminal: demandeVisa?.criminal,
        }

      )


      // setPrenom(demandeVisa?.prenom)
      // setNom(demandeVisa?.nom)
      // setNumeroPasseport(demandeVisa?.passportNumber)
      // setVisaNumber(demandeVisa?.visaNumber)
      // setGenre(demandeVisa?.sexe)
      // setJobTitle(demandeVisa?.occupation?.name)
      // // setSalary(employerF?.salary)
      // setDataExpiration(demandeVisa?.dateExpiration)
      // setBaseSalary(demandeVisa?.baseSalary)
      // setIndLogement(demandeVisa?.indLogement)
      // setIndTransport(demandeVisa?.indTransport)
      // setIndResponsabilite(demandeVisa?.indResponsabilite)
      // setIndRepresentation(demandeVisa?.indRepresentation)
      // setPrimeExploitation(demandeVisa?.primeExploitation)
      // setSursalaire(demandeVisa?.sursalaire)
      // setPrimeSalissure(demandeVisa?.primeSalissure)
      // setIndCaisse(demandeVisa?.indCaisse)
      // setBonus(demandeVisa?.bonus)
      // setPrimePanier(demandeVisa?.primePanier)
      // setbilletAvion(demandeVisa?.billetAvion)
      // setAutresPrimes(demandeVisa?.autresPrimes)

    }
  }, [employerF, demandeVisa]);
  // useEffect(() => {
  //   if ((employer == null || employer == undefined) && (location.state?.employer == undefined)) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [employer, location]);

  useEffect(() => {
    console.log("errorss======================>", errors)
    if (errors) {
      Object.keys(dataTab1).map((key) => {
        if (errors[key] != null || errors[key] != undefined) {
          setIsVisaDetailFull(true)

        }
      })

      Object.keys(dataTab2).map((key) => {
        if (errors[key] != null || errors[key] != undefined) {
          setIsAppInfoFull(true)
        }
      })

      Object.keys(documents).map((key) => {
        if (errors[key] != null || errors[key] != undefined) {
          setIsDocFull(true)
        }
      })
    }
  }, [errors]);



  return (
    <>
      <PassportNumberModal
        open={open}
        handleClose={handleClose}
        setEmployer={setEmployer}
      />
      <div className="wrapper">
        <Sidebar
          color={color}
          image={hasImage ? image : ""}
          routes={dashboardRoutes}
        />
        <div className="main-panel" ref={mainPanel}>
          <Header />

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <span className="alert alert-info text-center h5">
              {t("tabForms.titleEmp")}{" "}
              {location?.state?.employer?.fullName ?? location?.state?.employer?.firstName+" "+location?.state?.employer?.lastName}
            </span>
          </div>
          {successful ? <div id="preloader"></div> : <> </>}
          {error && (
            <Alert dismissible={true} key="danger" variant="danger">
              {message}
            </Alert>
          )}
          {success && (
            <Alert dismissible={true} key="success" variant="success" className="rounded align-items-center shadow">
              {message}
            </Alert>
          )}
          <div className="content">
            {compte && compte?.status <= 1 ? (
              <span className="alert alert-danger text-center m-3 shadow rounded h2">
                {" "}
                {t("tabForms.alertPending")}{" "}
              </span>
            ) : (
              ""
            )}
            <Tabs
              defaultActiveKey="details"
              id="fill-tab-example"
              // className="mb-3"
              activeKey={currentTab}
              onSelect={(key) => {
                if (demandeVisa?.step1 == "1" && demandeVisa?.step2 == "1" && demandeVisa?.step3 == "1" && demandeVisa?.step4 == "1")
                  setCurrentTab(key)
                else {
                  return null
                }

              }}
              fill
            >

              <Tab
                tabClassName={
                  isAppInfoFull
                    ? "text-danger fw-bold text-uppercase "
                    : demandeVisa?.step2 ? "text-success fw-bold text-uppercase " : "text-warning fw-bold text-uppercase "

                }
                // disabled={currentTab !== 1}
                eventKey={0}
                title={t("tabForms.titles.2")}
              >
                <Tab2
                  isAppInfoFull={isAppInfoFull}
                  data={{
                    ...dataTab2, genres, jobs,
                    maritals,
                    countries,
                    nationalities,
                    // contractDurations,
                    currencies,
                    employer: location?.state?.employer
                  }}
                  set={setTab2}
                  errors={errors}
                  next={next}
                ></Tab2>
              </Tab>
              <Tab
                tabClassName={
                  isVisaDetailFull
                    ? "text-danger fw-bold text-uppercase "
                    : demandeVisa?.step1 ? "text-success fw-bold text-uppercase " : "text-warning fw-bold text-uppercase "
                }
                // disabled={currentTab !== 0}
                eventKey={1}
                title={t("tabForms.titles.1")}
              >
                <Tab1
                  compte={compte}
                  isGood={demandeVisa?.step1}
                  isVisaDetailFull={isVisaDetailFull}
                  data={{
                    ...dataTab1, typeVisas,
                    modeTransports,
                    typeEntrees,
                    choices,
                  }}
                  errors={errors}
                  set={setTab1}
                  employer={employer}
                  next={next}
                  prev={prev}
                ></Tab1>
              </Tab>

              <Tab
                tabClassName={
                  // !isDocFull
                  !isDocFull
                    ? "text-danger fw-bold text-uppercase "
                    : demandeVisa?.step3 ? "text-success fw-bold text-uppercase " : "text-warning fw-bold text-uppercase "

                }
                // disabled={currentTab !== 2}
                eventKey={2}
                title={t("tabForms.titles.3")}
              >
                <Tab3
                  setIsDocFull={setIsDocFull}
                  isDocFull={isDocFull}
                  documents={documents}
                  data={dataDocument}
                  set={{ setDocuments }}
                  next={next}
                  prev={prev}
                  errors={errors}
                  demandeVisaId={demandeVisaId || demandeVisa?.id}
                  demandeVisa={demandeVisa}
                  setDemandeVisa={setDemandeVisa}
                ></Tab3>
              </Tab>
              <Tab
                tabClassName={
                  demandeVisa?.step4 ? "text-success fw-bold text-uppercase " : "text-warning fw-bold text-uppercase "


                }
                // disabled={currentTab !== 3}
                eventKey={3}
                title={t("tabForms.titles.4")}
              >
                <Tab4
                  next={next}
                  set={{ save }}
                  isVisaDetailFull={isVisaDetailFull}
                  isAppInfoFull={isAppInfoFull}
                  isDocFull={isDocFull}
                  errors={errors}
                  data={{ ...dataTab1, ...dataTab2 }}
                  prev={prev}
                ></Tab4>
              </Tab>
              {/* <Tab
                tabClassName="text-dark fw-bold text-uppercase"
                disabled={currentTab !== 4}
                eventKey={4}
                title={t("tabForms.titles.5")}
              >
                <Tab5
                  set={{ save }}
                  data={
                    {
                      numeroPasseport,
                      typePasseport: typePassport?.value,
                      dateEmission,
                      lieuEmission,
                      dateExpiration,


                      visaNumber,
                      visaPurpose,
                      typeVisa: typeVisa?.value,
                      typeEntree: typeEntree?.value,
                      dateDepart,
                      dateRetour,


                      portEntry,
                      arrivalDate,
                      lengthStay,
                      addressCmr,
                      placeWork,
                      modeTransport: modeTransport?.value,




                      prenom,
                      nom,
                      dateNaissance,
                      lieuNaissance,
                      genre: genre?.value,
                      codePays: codePays?.value,
                      nationalite: nationalite?.value,
                      maritalStatus: maritalStatus?.value,
                      nombreEnfants,
                      nativeLanguage,

                      //
                      jobTitle,
                      contractDuration: contractDuration?.value,
                      numperiod,
                      //
                      contractStart,
                      salary,
                      confirm_salary,
                      currency: currency?.value,
                      prime,
                      professionalFees,
                      indemnite,
                      baseSalary,

                      //
                      email,
                      phone,


                      //
                      numeroMaisonPermanente,
                      adresse1Permanente,
                      adresse2Permanente,
                      villePermanente,
                      regionPermanente,
                      codePostalPermanente,
                      paysPermanente: paysPermanente?.value,

                      //
                      fullNameE,
                      phoneE,
                      emailE,
                    }}
                  prev={prev}
                ></Tab5>
              </Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TabForm;
