import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Head from '../../components/Head';
import Header from '../../components/Header';
import VisaSteps from '../../components/VisaSteps';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Importing whatsapp floating button component
// Documentation at https://www.npmjs.com/package/react-floating-whatsapp

import { FloatingWhatsApp } from 'react-floating-whatsapp';

import { Trans, useTranslation } from 'react-i18next';
import { Mtoast } from '../../components/utils/toast';
import Tour from '../../components/utils/Tour';
import { Accordion } from 'react-bootstrap';



const Home = () => {
    const faqData = [
        { question: "What is the difference between work permit and work visa?", answer: "Yes, you can buy it wholesale by contacting our sales team." },
        { question: "How do I apply for a work permit?", answer: "Liquid Death is available on our website and at major retailers." },
        { question: "Are all documents listed of the information sheet applicable?", answer: "You can find it in selected stores or order online." },
        { question: "How much does work permit cost?", answer: "You can find it in selected stores or order online." },
        { question: "How long is the validity of work permit?", answer: "You can find it in selected stores or order online." },
        { question: "What are some common reasons for work permit denials?", answer: "You can find it in selected stores or order online." },
        { question: "What should I do if my work permit application is denied?", answer: "You can find it in selected stores or order online." },
        { question: "Can I apply for a work permit if I am self-employed?", answer: "You can find it in selected stores or order online." },
        { question: "Do I need a work permit if I own the business?", answer: "You can find it in selected stores or order online." },
        { question: "Does a temporal worker need a work permit?", answer: "You can find it in selected stores or order online." },
        { question: "How long does the work Permit process?", answer: "You can find it in selected stores or order online." },
        { question: "I don’t have information of my first entry into the country?", answer: "You can find it in selected stores or order online." },
        { question: "Some salary details do not appear on platform, what do I do?", answer: "You can find it in selected stores or order online." },
    ];

    return (
        <>
            <Header />
            <Head />
            <Tour />
            <main id="main">
                <section id="services" className="services sections-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>
                                <Trans i18nKey="services.title" >
                                    Request and Pay for Work-Permit
                                </Trans>

                            </h2>
                            <p>
                                <Trans i18nKey="services.welcome">

                                    Welcome to the eWorkpermit platform. Foreigners seeking employment in the Republic of Cameroon must complete the online application form and submit online
                                </Trans>
                            </p>
                        </div>

                        <VisaSteps />

                        {/* <div className="container mt-5">
                            <h2 className="mb-1">FAQs</h2>
                            <Accordion>
                                {faqData.map((item, index) => (
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                        <Accordion.Header>{item.question}</Accordion.Header>
                                        <Accordion.Body>{item.answer}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div> */}

                    </div>
                </section>

                {/* whatsapp floating button */}
                {/* <FloatingWhatsApp
                    phoneNumber="+237 6 95 34 65 75"
                    accountName="MINEFOP"
                    avatar="assets/img/logo.jpeg"
                    allowEsc
                    allowClickAway
                    notification
                    notificationSound
                /> */}
            </main>
            <Footer />
        </>
    )
}

export default Home;