import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userActions } from '../_store';
import SlideBar from './AccountStep';


import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

import { Trans, useTranslation } from "react-i18next";

const Head = () => {

    const dispatch = useDispatch();
    const { user: authUser } = useSelector(x => x.auth);

    useEffect(() => {
        dispatch(userActions.getAll());
    }, []);

    const [open, setOpen] = useState(false);

    const { t, i18n } = useTranslation();
    return (

        <section id="hero" className="hero">

            <div className="container position-relative">

                <div className="row gy-5 text-center" data-aos="fade-in">
                    <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">

                        <h2 className='fs-1'>{t("header.welcome")}</h2>

                        <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            slides={[
                                {
                                    type: "video",
                                    width: 1280,
                                    height: 720,
                                    autoPlay: true,
                                    poster: "assets/videos/tuto_poster.png",
                                    sources: [
                                        {
                                            src:
                                                "assets/videos/tuto.webm",
                                            type: "video/webm"
                                        }
                                    ]
                                }
                            ]}
                            plugins={[Video]}
                        />

                        <p>
                            {/* Welcome to the eWorkpermit platform. Foreigners seeking employment in the Republic of Cameroon must complete the online application form and submit online. Please <a href="assets/Drmo.pdf" className="btn-watch-video" download="Drmo.pdf">click here</a> for conditions to be met by persons of foreign nationality wishing to work in The Republic of Cameroon. */}
                            <Trans i18nKey="header.welcomeDesc" >
                                Welcome to the eWorkpermit platform. Foreigners seeking employment in the Republic of Cameroon must complete the online application form and submit online. Please
                                {i18n.language == "en" ?
                                    <Link to="/requirements-en" className="btn-watch-video" >click here</Link> :
                                    <Link to="/requirements" className="btn-watch-video" >click here</Link>}
                                for conditions to be met by persons of foreign nationality wishing to work in The Republic of Cameroon.
                            </Trans>
                        </p>
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            {!authUser && <Link to="/login" className="btn-get-started step6">{t('header.btn.1')} </Link>}
                            <span onClick={() => setOpen(true)} className="glightbox btn-watch-video d-flex align-items-center step3"><i className="bi bi-play-circle"></i><span>
                                {t('header.btn.2')}</span></span>
                            <Link
                                href="/assets/manual.pdf"
                                download={`manual.pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="glightbox btn-watch-video d-flex align-items-center step4"
                            >
                                <i className="bi bi-file"></i>
                                <span>{t('header.btn.4')}</span>
                            </Link>



                            {/* <Link to="/requirements" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-files"></i><span>
                                {t('header.btn.3')}</span></Link> */}
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <img src="assets/img/flag.png" className="img-fluid" width="450rem" alt="" data-aos="zoom-out" data-aos-delay="100" />
                    </div>
                </div>
            </div>

            <div className="icon-boxes position-relative ">
                <div className="container position-relative ">
                    <div className="row gy-6 mt-5" data-aos="fade-left">
                        <SlideBar />
                    </div>
                </div>
            </div>
        </section >
    )
}
export default Head;