import React, { Component, useEffect, useRef, useState} from 'react';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col
} from "react-bootstrap";
import { t } from "i18next"
import Preview from './Preview';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';
const Tab4 = ({ prev, data, next, set, errors,  isVisaDetailFull, isAppInfoFull, isDocFull  }) => {
    const [privacy, setPrivacy] = useState(false)
    const [confirmInformation, setConfirmInformation] = useState(false)

    const dataToSave =
    {
        
        numeroPasseport: data?.numeroPasseport,
        typePasseport: data?.typePassport?.value,
        dateEmission: data?.dateEmission,
        lieuEmission: data?.lieuEmission,
        dateExpiration: data?.dateExpiration,

        visaNumber: data?.visaNumber ,
        visaPurpose: data?.visaPurpose ,
        typeVisa: data?.typeVisa?.value ,
        placeIssue : data?.placeIssue ,
        typeEntree: data?.typeEntree?.value ,
        dateDepart: data?.dateDepart,
        dateRetour: data?.dateRetour,
      

        portEntry: data?.portEntry,
        arrivalDate: data?.arrivalDate,
        lengthStay: data?.lengthStay,
        addressCmr: data?.addressCmr,
        placeWork: data?.placeWork,
        modeTransport: data?.modeTransport?.value,



        prenom: data?.prenom,
        nom: data?.nom,
        dateNaissance: data?.dateNaissance,
        lieuNaissance: data?.lieuNaissance,
        genre: data?.genre?.value,
        codePays: data?.codePays?.value,
        nationalite: data?.nationalite?.label,
        maritalStatus: data?.maritalStatus?.value,
        nombreEnfants: data?.nombreEnfants,
        nativeLanguage: data?.nativeLanguage,

        //
        jobTitle: data?.jobTitle,
        // contractDuration: data?.contractDuration?.value,
        numperiod: data?.numperiod,
        //
        contractStart: data?.contractStart,
        contractEnd: data?.contractEnd,
        // salary: data?.salary,
        // confirm_salary: data?.confirm_salary,
        currency: data?.currency,
        // prime: data?.prime,
        // professionalFees: data?.professionalFees,
        // indemnite: data?.indemnite,
        baseSalary: data?.baseSalary,

        //
        email: data?.email,
        phone: data?.phone,


        //
        numeroMaisonPermanente: data?.numeroMaisonPermanente,
        adresse1Permanente: "fakeAddress",
        adresse2Permanente: "fakeAddress",
        villePermanente: data?.villePermanente,
        regionPermanente: data?.regionPermanente,
        codePostalPermanente: data?.codePostalPermanente,
        paysPermanente: data?.paysPermanente?.value,

        //
        fullNameE: data?.fullNameE,
        phoneE: data?.phoneE,
        emailE: data?.emailE,
    }


    useEffect(() => {
        console.log("data to save :", dataToSave)
    }, [privacy, confirmInformation, dataToSave])

    return (
        <>
            <Container fluid>
                <div>
                    <div>
                        <Preview data={data} prev={prev} next={next} />
                    </div>
                    <div className='d-flex flex-row mt-md-2 p-4 ml-2 text-danger'>
                        <div>
                            <div>
                                <input  className="form-check-input" type="checkbox" id="confirmInformation" onChange={(e) => { setConfirmInformation(e.target.checked) }} name="confirmInformation" checked={confirmInformation} />
                                <label for={"confirmInformation"} className="form-check-label fw-bold" >{t("ideclare")}</label>
                            </div>
                            <div>
                                <input   className="form-check-input" type="checkbox" id="privacy" name="privacy" onChange={(e) => { setPrivacy(e.target.checked) }} checked={privacy} />
                                <label for={"privacy"}  className="form-check-label fw-bold">{t("iread")}
                                {/* <Link to='/policy'>read</Link> */}
                              
                                </label>
                            </div>
                        </div>
                    </div>
                    <Row className="p-2 mt-5 " >

                        <Col className="ml-1" md="4">
                            <Button
                                className="btn-fill pull-left border-0 shadow-sm"
                                onClick={() => prev()}
                                variant="success"
                                style={{
                                    backgroundColor: "#008374"
                                }}
                            >
                                {t('tabForms.prev')}
                            </Button>
                        </Col>

                        {!(privacy && confirmInformation) ?
                            <Col className="pr-4">
                                <Button
                                    className="btn-fill pull-right border-0 shadow-sm"
                                    // onClick={() => next()}
                                    variant="success"
                                    style={{
                                        backgroundColor: "#008374",
                                        cursor: 'default'
                                    }}
                                    disabled={true}
                                >
                                    {t('tabForms.submit')}
                                </Button>
                            </Col>
                            : <Col className="pr-4">
                                <Button
                                    className="btn-fill pull-right border-0 shadow-sm"
                                    // onClick={() => next()}
                                    onClick={() => {
                                        // console.log(!isVisaDetailFull , !isAppInfoFull , isDocFull)ss
                                        set.save(dataToSave)
                                        // if (!isVisaDetailFull && !isAppInfoFull && isDocFull)
                                        // else toast.error('Please Complete all required files before')

                                    }}

                                    variant="success"
                                    style={{
                                        backgroundColor: "#008374"
                                    }}
                                    disabled={false}
                                >
                                    {t('tabForms.submit')}
                                </Button>
                            </Col>
                        }
                    </Row>
                </div>
            </Container>
        </>
    )
}
export default Tab4;