import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Requiremets() {
  
      const navigate = useNavigate();
      const { t, i18n } = useTranslation();
  
      useEffect(() => {
          if (i18n.language == "en") {
              navigate("/requirements-en");
          }
      }, [i18n])
    return (
        <>
                <Header />
            <div className="container ">

                    <h1 className="text-center p-4 m-6">
                        Informations utiles
                    </h1>
                    <hr></hr>
                <div className="section m-md-5  shadow p-2 p-md-4" data-aos="fade-out">

                    <h2>
                        1 – Conditions à remplir par les personnes de nationalité étrangère désireuses de travailler
                        au Cameroun
                    </h2>
                    <p>
                        Les personnes de nationalité étrangère qui désirent
                        exercer une activité professionnelle au Cameroun doivent,
                        avant tout commencement d'exécution, faire viser leur contrat de travail par le Ministre en charge des questions d'emplois. Article 27 alinéa (2) du Code du Travail. De même tout
                        renouvellement de visa doit obéir à la même obligation
                        (Article 25 alinéa 2).
                    </p>
                    <p>
                        La procédure d'obtention de ce visa est la suivante
                        :
                    </p>
                    <ul>
                        <li>
                            le contrat de travail est déposé ou adressé par lettre recommandée, avec avis de réception auprès des services
                            compétents du Ministère de l'Emploi et de la Formation Professionnelle ;
                        </li>
                        <li>
                            l'envoi ou le dépôt s'effectue à la diligence de l'employeur,
                            en six (06) exemplaires datés et signés par les deux parties.
                        </li>
                    </ul>

                    <p>
                        Il est joint au dossier tous les documents susceptibles d'établir la qualification et la compétence professionnelle du travailleur au regard de l'emploi et de la catégorie
                        portés au contrat, ou de justifier l'occupation du poste à
                        pourvoir par un étranger, notamment :
                    </p>
                    <ol>
                        <li>
                            un certificat médical datant de moins de trois (03) mois
                            délivré par une autorité médicale du lieu de recrutement, et
                            attestant que l'intéressé est apte à exercer son activité au
                            Cameroun ;
                        </li>
                        <li>
                            les références professionnelles ou universitaires du
                            candidat, telles que les copies certifiées conformes des certificats de travail ou de diplômes ;
                        </li>

                        <li>
                            Une note descriptive détaillée de l'emploi dont il s'agit, faisant ressortir le profil correspondant ;
                        </li>

                        <li>
                            un curriculum vitae du candidat ;
                        </li>

                        <li>
                            Un extrait de casier judiciaire datant de moins de trois (03)
                            mois ou toute pièce légale en tenant lieu ;
                        </li>

                        <li>
                            une copie certifiée conforme du titre d'entrée et de séjour
                            au Cameroun pour les expatriés qui s'y trouvent déjà ;
                        </li>

                        <li>
                            Une copie du marché, de la convention ou tout autre document justifiant l'occupation du poste par un étranger ;
                        </li>

                        <li>
                            Le plan de camerounisation des emplois, approuvé par le
                            Ministre chargé des questions d'emploi ;
                        </li>

                        <li>
                            un organigramme détaillé de l'entreprise, faisant ressortir
                            tous les postes de cadres et d'agents de maîtrise, assortis des
                            profils correspondants.
                        </li>

                        <li>
                            Une note de présentation de la situation de la maind’œuvre faisant ressortir les aspects suivants:
                            <ul>
                                <li>
                                    L’effectif global de la main-d’œuvre repartie par nationalité et par catégorie;
                                </li>
                                <li>
                                    Les postes occupés par les travailleurs de nationalité
                                    étrangère assortis de leurs profils et de leur ancienneté au
                                    sein de la société;
                                </li>
                                <li>
                                    Les recrutements effectués ou envisagés à court terme
                                    assortis des profils correspondants;
                                </li>

                                <li>
                                    La politique de promotion de la main-d’œuvre nationale
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>

                <div className="section  m-md-5  shadow p-2 p-md-4" data-aos="fade-up">
                    <h2>
                        2- MODALITES DE RECRUTEMENT
                    </h2>

                    <p>
                        Les dispositions des points 9 et 10 de la circulaire N°005/PM/
                        du 13 juin 2012 relatives aux clauses générales applicables
                        aux investisseurs étrangers stipulent que nonobstant les dispositions du Code du travail relatives au visa des contrats des
                        travailleurs de nationalité étrangère, les emplois de manœuvre,
                        d’ouvrier, d’employé ou d’agent de maîtrise sont prioritairement
                        occupés par les travailleurs nationaux.
                    </p>

                    <p>
                        Dans tous les cas, les emplois doivent être occupés par les
                        nationaux qualifiés et compétents s’il en existe à concurrence
                        de:
                    </p>

                    <ul>
                        <li>
                            De 50% au moins pour les emplois d’encadrement;
                        </li>
                        <li>
                            De 60% au moins pour les emplois de maîtrise;
                        </li>
                        <li>
                            De 85% au moins pour les emplois d’exécution.
                        </li>
                    </ul>

                    <p>
                        Aussi, le recrutement du personnel de l’investisseur étranger
                        s’effectue en conformité avec le Code du travail, la convention
                        collective du secteur d’activité concerné et les dispositions
                        particulières de la présente circulaire
                    </p>
                </div>


                <div className="section  m-md-5  shadow p-2  p-md-4" data-aos="fade-left">
                    <h2>
                        3– TRANSFERT DE TECHNOLOGIE
                    </h2>

                    <p>
                        L’investisseur étranger s’engage à mettre en œuvre des programmes de formation en faveur du personnel national dans
                        le secteur concerné, ainsi que dans tous les domaines de ses
                        activités et de son développement commercial, en vue de la
                        « camerounisation » progressive des postes de responsabilité
                        et du transfert des technologies.
                    </p>

                    <p>
                        En matière de formation, la formation continue, permanente et
                        le perfectionnement de son personnel aux métiers et spécialités du secteur concerné. A cet effet, l’entreprise:
                    </p>

                    <ul>
                        <li>
                            Soutient par tout apport matériel, financier ou autre, la
                            politique de formation secondaire et supérieure dans
                            les métiers du secteur concerné;
                        </li>
                        <li>
                            Encourage la recherche-développement dans son
                            secteur d’activités.
                        </li>
                    </ul>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Requiremets;