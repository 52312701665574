import './App.css';
import { Route, Routes, BrowserRouter, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Home from './app/pages/Home';
import Login from './app/pages/Login';
import Register from './app/pages/Register';
import ForgetPassword from './app/pages/ForgetPassword';
import Admin from './layouts/Admin';
import Dashboard from './views/Dashboard';
import User from './views/UserProfile';
import { history } from './_helpers';
import PrivateRoute from './components/PrivateRoute';
import TabForm from './views/TabForm';
import DemandeVisaProfile from './views/DemandeVisaProfile';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './actions/auth';
import Verify from './app/pages/Verify';
import News from './app/pages/news';
import DetailsNew from './app/pages/news/details';
import Intervention from './components/Intervention';
import VisaApproved from './views/VisaApproved';
import Requiremets from './components/Requirements';
import ContactUs from './components/ContactUs';
import { toast, Toaster } from 'react-hot-toast';
import ResetPAsswordModal from './app/pages/ResetPasswordModal';
import UserRealProfile from './views/UserRealProfile';
import EditDemande from './views/EditDemande';
import ForeinWorkers from './views/ForeignWorkers';
import ApplicationByEmployee from './views/ApplicationByEmployee';
import Guide from './components/utils/Guide';
import Tour from './components/utils/Tour';
import TourPermit from './components/utils/TourPermit';
import RequiremetsEn from './components/RequirementsEn';
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  history.navigate = navigate;
  history.location = location;

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };



  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      // console.log('Current user :',currentUser)s
      const decodedJwt = parseJwt(currentUser.token);
      const bool = false;
      if (decodedJwt.exp * 1000 < Date.now()) {
        bool = true;
        logOut();
      }
      // toast(`Hello${ currentUser?.user?.name}`)
    }
  }, [location]);


  return (
    <>
      <div><Toaster /></div>
      <Guide/>
      {/* <TourPermit /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path='/news' element={<News />} />
        <Route exact path='/new/:id' element={<DetailsNew />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verify" element={<Verify />} />
        <Route exact path="/requirements" element={<Requiremets />} />
        <Route exact path="/requirements-en" element={<RequiremetsEn />} />
        {/* <Route exact path="/forgetpassword" element={<ResetPAsswordModal />} /> */}
        <Route exact path="/contactUs" element={<ContactUs />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/user"
          element={
            <PrivateRoute>
              <User />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <UserRealProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/form"
          element={
            <PrivateRoute>
              <TabForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/form/:demandeVisaId"
          element={
            <PrivateRoute>
              <TabForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/demandes/:id"
          element={
            <PrivateRoute>
              <DemandeVisaProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/demandes/:id/edit"
          element={
            <PrivateRoute>
              <EditDemande />
            </PrivateRoute>
          }
        />



        <Route
          path="/interventions"
          element={
            <PrivateRoute>
              <Intervention />
            </PrivateRoute>
          }
        />

        <Route
          path="/demandes-by-employee"

          element={
            <PrivateRoute>
              <ApplicationByEmployee />
            </PrivateRoute>
          }
        />

        <Route
          path="/approvedVisa"

          element={
            <PrivateRoute>
              <VisaApproved />
            </PrivateRoute>
          }
        />
        <Route
          path="/foreignWorkers"

          element={
            <PrivateRoute>
              <ForeinWorkers />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
